import { getAnalytics } from 'firebase/analytics';
import { getApps, initializeApp } from 'firebase/app';
import { isConfigValid } from "./isConfigValid"


const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const createFirebaseApp = () => {
  if (getApps().length <= 0 && isConfigValid(clientCredentials)) {
    const app = initializeApp(clientCredentials);
    if (typeof window !== 'undefined') {
      if ('measurementId' in clientCredentials) {
        getAnalytics();
      }
    }
    return app;
  }

  return undefined;
};