import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import moment from 'moment';

import { getType } from '../../../pages/Admin/site/dataAdapters';
import i18n from '../../../i18n';
import { FROM_DATE_FOR_MORE_THAN } from './DateRangeFilter';
import { AffiliateProductObj, FILTER_MENU_SELECT_LIST_VALUES } from '../../../constants/Filter';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentsList } from '../../../store/agents/agents/actions';
import {DATE_TIME_FORMAT_ss} from "../../../common/utils/common";
import { useTranslation } from "react-i18next";
import useVipLevelMapping from "../../../hooks/useVipLevelMapping";

const MAX_BADGE_TEXT_LENGTH = 27;
const SLICED_TEXT_LENGTH = 24;

const roundOff = (number, maxCalculationError) => {
    if (Math.abs(number - +Math.round(number)) < maxCalculationError) {
        return +Math.round(number);
    }

    return number;
};

const prepareDateRangeValue = value => {
    const dateCurrentMidnight = moment(new Date().setHours(24, 0, 0, 0));
    const dateCurrent = moment(new Date());

    const from = moment(value.from);
    const to = moment(value.to);

    // dateRange created according to the rules FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN
    const isRangeTypeMoreThan = from.valueOf() === FROM_DATE_FOR_MORE_THAN;

    const midnightToDiff = moment.duration(dateCurrentMidnight.diff(to));
    const toFromDiff = moment.duration(to.diff(from));
    const currentToDiff = moment.duration(dateCurrent.diff(to));

    const isFromToDiffAliquotYear = Number.isInteger(
        roundOff(toFromDiff.asYears(), 0.05)
    );
    const isFromToDiffAliquotMonth = Number.isInteger(
        roundOff(toFromDiff.asMonths(), 0.05)
    );
    const isFromToDiffAliquotWeek =
        (to.valueOf() - from.valueOf()) % (7 * 24 * 60 * 60 * 1000) === 0;
    const isFromToDiffAliquotDay =
        (to.valueOf() - from.valueOf()) % (24 * 60 * 60 * 1000) === 0;
    const isFromToDiffAliquotHours =
        (to.valueOf() - from.valueOf()) % (60 * 60 * 1000) === 0;
    const isFromToDiffAliquotMinutes =
        (to.valueOf() - from.valueOf()) % (60 * 1000) === 0;

    const isCurrentMidnightToDiffAliquotYear = Number.isInteger(
        roundOff(currentToDiff.asYears(), 0.05)
    );
    const isCurrentMidnightToDiffAliquotMonth = Number.isInteger(
        roundOff(currentToDiff.asMonths(), 0.05)
    );
    const isCurrentMidnightToDiffAliquotWeek =
        (dateCurrentMidnight.valueOf() - to.valueOf()) %
            (7 * 24 * 60 * 60 * 1000) ===
        0;
    const isCurrentMidnightToDiffAliquotDay =
        (dateCurrentMidnight.valueOf() - to.valueOf()) %
            (24 * 60 * 60 * 1000) ===
        0;

    // Try to catch FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST range type
    // 'roundOff' to simplify the work with the month and year
    if (
        midnightToDiff.asDays() === 0 &&
        roundOff(toFromDiff.asYears(), 0.05) > 0 &&
        isFromToDiffAliquotYear
    ) {
        return i18n.t('filter.label.lastYears', {
            count: Math.round(toFromDiff.asYears())
        });
    } else if (
        midnightToDiff.asDays() === 0 &&
        roundOff(toFromDiff.asMonths(), 0.05) > 0 &&
        isFromToDiffAliquotMonth
    ) {
        return i18n.t('filter.label.lastMonths', {
            count: Math.round(toFromDiff.asMonths())
        });
    } else if (midnightToDiff.asDays() === 0 && isFromToDiffAliquotWeek) {
        return i18n.t('filter.label.lastWeeks', {
            count: toFromDiff.asWeeks()
        });
    } else if (
        midnightToDiff.asDays() === 0 &&
        toFromDiff.asDays() > 0 &&
        isFromToDiffAliquotDay
    ) {
        return i18n.t('filter.label.lastDays', { count: toFromDiff.asDays() });
    } else if (
        Math.floor(currentToDiff.asHours()) === 0 &&
        toFromDiff.asHours() > 0 &&
        isFromToDiffAliquotHours
    ) {
        return i18n.t('filter.label.lastHours', {
            count: toFromDiff.asHours()
        });
    } else if (
        Math.floor(currentToDiff.asMinutes()) === 0 &&
        toFromDiff.asMinutes() > 0 &&
        isFromToDiffAliquotMinutes
    ) {
        return i18n.t('filter.label.lastMinutes', {
            count: toFromDiff.asMinutes()
        });
    }

    // Try to catch FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN range type
    if (isRangeTypeMoreThan &&
      isCurrentMidnightToDiffAliquotYear &&
      Math.floor(currentToDiff.asYears()) > 0
    ) {
        return i18n.t('filter.label.moreThenYearsAgo', {
            count: Math.round(midnightToDiff.asYears())
        });
    } else if (isRangeTypeMoreThan &&
      isCurrentMidnightToDiffAliquotMonth &&
      Math.floor(currentToDiff.asMonths()) > 0
    ) {
        return i18n.t('filter.label.moreThenMonthsAgo', {
            count: Math.round(midnightToDiff.asMonths())
        });
    } else if (isRangeTypeMoreThan &&
      isCurrentMidnightToDiffAliquotWeek &&
      Math.floor(currentToDiff.asWeeks()) > 0
    ) {
        return i18n.t('filter.label.moreThenWeeksAgo', {
            count: midnightToDiff.asWeeks()
        });
    } else if (isRangeTypeMoreThan &&
      isCurrentMidnightToDiffAliquotDay &&
      Math.floor(currentToDiff.asDays()) > 0
    ) {
        return i18n.t('filter.label.moreThenDaysAgo', {
            count: midnightToDiff.asDays()
        });
    } else if (isRangeTypeMoreThan && Math.floor(currentToDiff.asHours()) > 0) {
        return i18n.t('filter.label.moreThenHoursAgo', {
            count: Math.floor(currentToDiff.asHours())
        });
    } else if (
        isRangeTypeMoreThan &&
        Math.floor(currentToDiff.asMinutes()) > 0
    ) {
        return i18n.t('filter.label.moreThenMinutesAgo', {
            count: Math.floor(currentToDiff.asMinutes())
        });
    }

    // default view
    return i18n.t('filter.label.fromToTo', {
        from: moment(value.from).format(DATE_TIME_FORMAT_ss),
        to: moment(value.to).format(DATE_TIME_FORMAT_ss)
    });
};


const FilterBadge = ({ fieldKey, value, index, onRemoveFilter, onEditFilter }) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { agentsList } = useSelector(state => state.AgentList);
    const { uniqueGames } = useSelector(state => state.PlayerGamePlay);
    const { gameProviders } = useSelector(state => state.Games);
    const { getVipLevelBrandName, state: stateVipBrand } = useVipLevelMapping();

    const gamePages = location.pathname.includes('/games-db') ||
        location.pathname.includes('/game-management')

    useEffect(() => {
        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER) {
            dispatch(getAgentsList());
        }
    }, []);

    const valueToDisplay = useMemo(() => {
        const isObjectValue = getType(value) === 'object';

        let result = value;

        if (value === null && fieldKey === FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER) {
            result = i18n.t('filter.withoutOwner');
        }

        if (isObjectValue && fieldKey === FILTER_MENU_SELECT_LIST_VALUES.AMOUNT) {
            const key = Object.keys(value)[0];
            result = `${key} ${value[key]}`;
        }

        if (isObjectValue && value.hasOwnProperty('from')) {
            result = prepareDateRangeValue(value);
        }

        if (isObjectValue) {
            result = Object.keys(value).reduce((acc, cur) => {
                return acc === ''
                    ? `${cur}:${value[cur]}`
                    : `${acc}, ${cur}:${value[cur]}`;
            }, '');
        }

        if (typeof value === 'boolean') {
            result = i18n.t(`filter.constant.${value}`);
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.ACCOUNT_STATUS) {
            result = i18n.t(`filter.constant.${value}`);
        }

        if (
            fieldKey === FILTER_MENU_SELECT_LIST_VALUES.NATIONALITY ||
            fieldKey === FILTER_MENU_SELECT_LIST_VALUES.CITIZENSHIP
        ) {
            result = i18n.t(value);
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.LANGUAGE) {
            result = i18n.t(`language.${value}`);
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER) {
            const owner = (agentsList || []).find(item => item.id === value) || null;
            result = owner ? owner.agentName : i18n.t('filter.constant.noAgentOwner');
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.GAME) {
            const game = uniqueGames.find(({key}) => key === value );
            result = game.label;
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.GAME_PROVIDER) {
            const game = gameProviders.find(({contentProvider}) => contentProvider === value );
            result = game.name;
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.VIPLEVEL) {
            result = i18n.t(`filter.constant.${value}`);
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.PRODUCT) {
            result = i18n.t(`filter.constant.${Object.keys(AffiliateProductObj)[value - 1]}`);
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.TYPE && gamePages && value === '') {
            result = 'No type';
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.STATUS) {
            const isWithdrawalPage = location.pathname.includes('withdrawals')
              || location.search.includes('subTab=WITHDRAWALS');

            switch (value) {
                case 'COMPLETED':
                    result = i18n.t('filter.constant.SUCCEEDED');
                    break;
                case 'PROCESSING':
                    result = i18n.t('filter.constant.PROCESSING');
                    break;
                case 'ERROR':
                    result = i18n.t('filter.constant.ERROR');
                    break;
                case 'CANCELED':
                    result = i18n.t('filter.constant.CANCELED');
                    break;
                case 'REFUNDED':
                    result = isWithdrawalPage ? i18n.t('filter.constant.CANCELED_BY_PSP') : i18n.t('filter.constant.REFUNDED');
                    break;
                case 'DECLINED':
                    result = i18n.t('filter.constant.DECLINED');
                    break;
                case 'CHARGEBACK':
                    result = i18n.t('filter.constant.CHARGEBACK');
                    break;
                case 'REVERSE_CHARGEBACK':
                    result = i18n.t('filter.constant.REVERSE_CHARGEBACK');
                    break;
                case 'PENDING_FOR_APPROVAL':
                    result = i18n.t('filter.constant.PENDING_FOR_APPROVAL');
                    break;
                case 'PENDING_FOR_WIRE_TRANSFER':
                    result = i18n.t('filter.constant.PENDING_FOR_WIRE_TRANSFER');
                    break;
                case 'PARTIAL_REFUNDED':
                    result = i18n.t('filter.constant.PARTIAL_REFUNDED');
                    break;
                case 'PENDING_FOR_PLAYER':
                    result = i18n.t('filter.constant.PENDING_FOR_PLAYER');
                    break;
                case 'INITIALIZED':
                    result = i18n.t('filter.constant.INITIALIZED');
                    break;
                default:
                    break;
            }
        }

        if (fieldKey === FILTER_MENU_SELECT_LIST_VALUES.VIPLEVEL) {
            result = getVipLevelBrandName(value)
        }

        result = `${i18n.t(`filter.constant.${fieldKey}`)}: ${result}`;
        result = result?.length <= MAX_BADGE_TEXT_LENGTH
            ? result
            : `${result?.slice(0, SLICED_TEXT_LENGTH)}...`;

        return result;
    }, [value, agentsList, uniqueGames, gameProviders, stateVipBrand]);

    return (
        <Col className={'filter_badge'}>
            <span>
                {valueToDisplay}
            </span>

            <div
                className={'filter_badge-edit_btn'}
                onClick={onEditFilter(index)}
            >
                <div className={'filter_badge-edit_btn-icon'} />
            </div>

            <div
                className={'filter_badge-remove_btn'}
                onClick={onRemoveFilter(index)}
            >
                <div className={'filter_badge-remove_btn-icon'} />
            </div>
        </Col>
    );
};

FilterBadge.propTypes = {
    fieldKey: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
    onEditFilter: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool
    ])
};

export default React.memo(FilterBadge);
