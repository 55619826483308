import { GET_INFO } from './actionTypes';

import { takeEvery, call, put } from '@redux-saga/core/effects';
import { getCountersApi } from '../../helpers/backend_helper';
import { getInfoError, getInfoSuccess } from './actions';

export function* getInfoSaga() {
    try {
        const response = {
            siteTimeZone: undefined,
            pendingDocuments: 0,
            pendingWithdrawals: 0,
            pendingKYC: 0,
            onlinePlayers: 0,
        }
        yield put(getInfoSuccess(response));
    } catch (error) {
        yield put(getInfoError({ message: error }));
    }
}

function* infoSaga() {
    yield takeEvery(GET_INFO, getInfoSaga);
    // yield takeEvery(LOGIN_SUCCESS, getInfoSaga);
}

export default infoSaga;
