export const SITE_BRAND = {
    GCPLAYING: 'Gcplaying',
    WINPOTMX: 'Winpot.mx',
    SPINOLOCO: 'Spinoloco',
    WINSPINBET: 'Winspinbet',
    D20: 'd20bet',
    BETGCC: 'betgcc',
    SEVENPLAY: 'Sevenplay',
    JULIEBET: 'Juliebet',
    HEART9: 'Heart9',
    BLACK29: '29black',
    Dealer21: '21Dealer',
    LUCKYSTAKE: 'LuckyStake',
    MAXWIN: 'MaxWin',
    MAXWINGE: 'MaxWinGe'
};

export const SITE_BRAND_CDN = {
    [SITE_BRAND.WINPOTMX]: 'https://winpot-cdn.mx/',
    [SITE_BRAND.GCPLAYING]: 'https://wgaming-cdn.com/gcplaying/',
    [SITE_BRAND.SPINOLOCO]: 'https://wgaming-cdn.com/spinoloco/',
    [SITE_BRAND.WINSPINBET]: 'https://wgaming-cdn.com/winspinbet/',
}

export const SMARTICO_DRIVE_LINKS = {
    [SITE_BRAND.WINPOTMX]: 'https://drive.smartico.ai/4586#/users?displayedFilters=%7B%7D&filter=%7B%22disableAutoRequest%22%3Afalse%2C%22q%22%3A%22{{PLAYER_ID}}%22%2C%22__request_version%22%3A1712671818279%7D&order=DESC&page=1&perPage=10&sort=id'
}

export const COUNTRY_FLAG_CDN = (countryCode) => {
    return `https://wgaming-cdn.com/icons/country/square/${countryCode}.svg`
}

export const SITE_BRAND_METABASE = {
    [SITE_BRAND.WINPOTMX]: 'b6c23cda-bc91-4cab-8a14-5a29524de8d6',
    [SITE_BRAND.SPINOLOCO]: '8aafc102-50d7-436a-b82d-89cadbba13a7',
    [SITE_BRAND.GCPLAYING]: 'c62abbf7-18af-40bf-86b8-59b1d66beb21',
    [SITE_BRAND.Dealer21]: '86f1db9e-cd49-44dd-8cd2-4ede0738e0cc',
}


export const BI_STATISTICS_DASHBOARD = {
    [SITE_BRAND.WINPOTMX]: '321',
    [SITE_BRAND.MAXWINGE]: '78',
    [SITE_BRAND.SPINOLOCO]: '78',
}