import {SITE_BRAND} from "../../constants/siteBrands";

export const MENU_POINTS_KEYS = {
    // CRM
    overview: 'overview',
    playerList: 'playerList',
    playerReport: 'playerReport',
    pendingKYC: 'pendingKYC',
    affiliates: 'affiliates',

    // Cashier
    cashierDeposit: 'cashierDeposit',
    cashierWithdraw: 'cashierWithdraw',
    cashierSuppliers: 'cashierSuppliers',

    // Finance
    deposits: 'deposits',
    withdrawals: 'withdrawals',

    // Content
    altenarprovider: 'altenarprovider',
    gameCategories: 'gameCategories',
    gameManagement: 'gameManagement',

    //Engagement
    casinoPromotions: 'casinoPromotions',
    sportPromotions: 'sportPromotions',
    tracker: 'tracker',
    smartico: 'smartico',

    // Manage
    agentProfile: 'agentProfile',
    brandSettings: 'brandSettings',
    agents: 'agents',
    adminRole: 'adminRole',
    userManual: 'userManual',

    // Admin
    gamesDB: 'gamesDB',

    // Retail
    retail: 'retail',
}

export const siteSettings = {
    [MENU_POINTS_KEYS.playerReport]: new Set([
        SITE_BRAND.WINPOTMX,
        SITE_BRAND.SPINOLOCO,
        SITE_BRAND.GCPLAYING,
        SITE_BRAND.Dealer21,
    ]),
    [MENU_POINTS_KEYS.altenarprovider]: new Set([
        SITE_BRAND.WINPOTMX,
    ]),
    [MENU_POINTS_KEYS.sportPromotions]: new Set([
        SITE_BRAND.WINPOTMX,
        SITE_BRAND.BLACK29,
        SITE_BRAND.D20,
        SITE_BRAND.Dealer21,
        SITE_BRAND.SPINOLOCO,
    ]),
    [MENU_POINTS_KEYS.smartico]: new Set([
        SITE_BRAND.WINPOTMX,
        SITE_BRAND.WINSPINBET,
        SITE_BRAND.SPINOLOCO,
        SITE_BRAND.GCPLAYING,
        SITE_BRAND.BLACK29,
        SITE_BRAND.D20,
        SITE_BRAND.SEVENPLAY,
        SITE_BRAND.JULIEBET,
        SITE_BRAND.HEART9,
        SITE_BRAND.BETGCC,
    ]),
    [MENU_POINTS_KEYS.tracker]: new Set([
        SITE_BRAND.WINPOTMX,
    ]),
};