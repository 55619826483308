import { useEffect } from 'react';
import { createFirebaseApp } from "../../helpers/firebase-analytics"


function TriggerAnalytics() {
  useEffect(() => {
    createFirebaseApp();
  }, []);

  return <div />;
}

export default TriggerAnalytics;