import { SITE_PERMISSION } from "../../../../common/constants/common";
import { SITE_BRAND } from "../../../../constants/siteBrands";

const PLAYER_VIEW_DETAILED_TAB_TYPE = {
    GENERAL: 'GENERAL',
    VIP: 'VIP',
    JOINT: 'JOINT',
    COMMUNICATION: 'COMMUNICATION',
    DOCUMENTS: 'DOCUMENTS',
    VERIFICATION: 'VERIFICATION',
    FINANCE: 'FINANCE',
    RESTRICTIONS: 'RESTRICTIONS',
    GAME_PLAY: 'GAME_PLAY',
    BONUSES: 'BONUSES',
    AUDIT: 'AUDIT',
    WALLET_TRANSACTION: 'WALLET_TRANSACTION',
    REFERRAL: 'REFERRAL',
};

const PERMISSION_PER_PLAYER_VIEW_TAB = {
    [PLAYER_VIEW_DETAILED_TAB_TYPE.FINANCE]: [SITE_PERMISSION.Player__Transactions__View],
    [PLAYER_VIEW_DETAILED_TAB_TYPE.RESTRICTIONS]: [SITE_PERMISSION.Player__View_Restrictions_Tab],
    [PLAYER_VIEW_DETAILED_TAB_TYPE.BONUSES]: [SITE_PERMISSION.Promotion__Get_Promotions],
    [PLAYER_VIEW_DETAILED_TAB_TYPE.AUDIT]: [SITE_PERMISSION.Players__Audit],
    [PLAYER_VIEW_DETAILED_TAB_TYPE.REFERRAL]: [SITE_PERMISSION.Player__View_Referral_Info],
}

const BRAND_TABS_TO_HIDE = {
    // ex. what we want to hide from player view tabs per brand
    // [SITE_BRAND.WINPOTMX]: [PLAYER_VIEW_DETAILED_TAB_TYPE.GENERAL, PLAYER_VIEW_DETAILED_TAB_TYPE.VIP],
};

const SPORT_TAB_TO_HIDE = [
    SITE_BRAND.WINPOTMX,
    SITE_BRAND.D20,
    SITE_BRAND.BLACK29,
    SITE_BRAND.Dealer21,
    SITE_BRAND.SPINOLOCO,
];

const FINANCE_TYPES = {
    ERROR: 'Error',
    DECLINED: 'Declined',
    CANCELED: 'Canceled',
    REFUNDED: 'Refunded',
    PARTIAL_REFUNDED: 'PartialRefunded',
    PENDING_FOR_PLAYER: 'PendingForPlayer',
    COMPLETED: 'Succeeded',
    INITIALIZED: 'Initialized',
    PENDING_FOR_APPROVAL: 'Pending',
    PENDING_FOR_WIRE_TRANSFER: 'PendingForWireTransfer',
    PROCESSING: 'Processing',
    CHARGEBACK: 'Chargeback',
    REVERSE_CHARGEBACK: 'ReverseChargeback',
};

export {
    PLAYER_VIEW_DETAILED_TAB_TYPE,
    PERMISSION_PER_PLAYER_VIEW_TAB,
    FINANCE_TYPES, BRAND_TABS_TO_HIDE,
    SPORT_TAB_TO_HIDE,
};
